import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (store.state.token || to.path === '/login' || (to.query.auth && to.path==='/items')) {
    next()
  } else {
    next('/login')
  }
})

export default router
