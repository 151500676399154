import Vue from 'vue'
import App from './App.vue'
import 'normalize.css'
import './assets/style.less'
import store from './store'
import router from './router'
import TopBar from './components/TopBar.vue'

Vue.config.productionTip = false

Vue.component('top-bar', TopBar)
Vue.use(router)

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
