<template>
  <div class="top-bar">
    <div class="back" @click="handleBack">
      <img src="../assets/img/back.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
<style lang='less' scoped>
.top-bar {
  display: flex;
  height: 86px;
  align-items: center;
  padding: 0 36px;
  .back {
    width: 90px;
    img {
      height: 34px;
    }
  }
}
</style>
