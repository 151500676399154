import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isAuth: false,
    infoData: {},
    token: '',
    name: '',
    no: ''
  },
  mutations: {
    saveData (state, data) {
      state.infoData = data
      state.token = data.access_token
      state.name = data.person_name
      state.no = data.serial_no
    }
  },
  actions: {
    getData () {}
  }
})

export default store
