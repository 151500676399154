export default [
  {
    path: '/',
    name: 'default',
    redirect: '/main'
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/Main.vue')
  },
  {
    path: '/zong',
    name: 'zong',
    component: () => import('@/views/ViewZong.vue')
  },
  {
    path: '/jian',
    name: 'jian',
    component: () => import('@/views/ViewJian.vue')
  },
  {
    path: '/ae',
    name: 'ae',
    component: () => import('@/views/Ae.vue')
  },
  {
    path: '/items',
    name: 'items',
    component: () => import('@/views/items.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/List.vue')
  },
  {
    path: '/view',
    name: 'view',
    component: () => import('@/views/View.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/Info.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  }
]
